import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import axios from "axios";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BeaconLearningConstants } from "../../core/shared/constants/constants";

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  year = new Date().getFullYear();

  constructor(private fireStore: AngularFirestore, private http: HttpClient) {}

  /**
   *
   * @param email user email
   * @description returns user details using emailId
   */
  checkEmailExists(email) {
    return this.fireStore
      .collection("users")
      .ref.where("emailAddress", "==", email)
      .get();
  }

  /**
   * @description returns server time stamp
   */
  getServerTimeStamp() {
    return axios.get(
      "https://us-central1-beacon-learning-staging.cloudfunctions.net/getServerTime"
    );
  }

  /**
   *
   * @param gameId gameId which user entered.
   * @description returns game details
   */
  getGameDetails(gameId) {
    return this.fireStore
      .collection("game")
      .ref.where("gameId", "==", gameId)
      .get();
  }

  /**
   *
   * @description returns game list
   */
  getGamesList() {
    return this.fireStore.collection("game").ref.get();
  }

  /**
   *
   * @param data user details to update
   * @param refId users document refId
   * @description updates user profile details.
   */
  updateUserDetails(data, refId) {
    return this.fireStore.collection("users").doc(refId).update(data);
  }

  /**
   *
   * @param refId users document refId
   * @description returns user details by refId
   */
  getUserDetailsByRefId(refId) {
    return this.fireStore.collection("users").doc(refId).get();
  }

  /**
   *
   * @param gameDetails game details of game user enetered.
   * @description returns sponsor data according to sponsor Id
   */
  getSponsorData(gameDetails) {
    return this.fireStore
      .collection("sponsor")
      .doc(gameDetails.sponsorId)
      .get();
  }

  /**
   *
   * @param refId sponsor document refId
   * @description returns sponsor data using refId
   */
  fetchSponsorData(refId) {
    return this.fireStore.doc("sponsor/" + refId).valueChanges();
  }

  /**
   *
   * @param gameId gameId which user entered.
   * @description returns questions for game
   */
  fetchQuestionsForQuiz(gameId) {
    return this.fireStore
      .collection("question")
      .ref.where("gameId", "==", gameId)
      .orderBy("questionNo", "asc")
      .get();
  }

  /**
   *
   * @param userId userId
   * @param gameId gameId
   * @description returns user answered questions for specific game and userID
   */
  getUserAnsweres(userId, gameId) {
    return this.fireStore
      .collection("userAnswer")
      .ref.where("userId", "==", userId)
      .where("gameId", "==", String(gameId))
      .orderBy("questionedAnsweredProgress", "desc")
      .get();
  }

  /**
   *
   * @param userId userId
   * @param gameId gameId
   * @description returns user answered questions for specific game and userID
   */
  getUserAnsweredData(userId, gameId) {
    return this.fireStore
      .collection("userAnswer")
      .ref.where("userId", "==", userId)
      .where("gameId", "==", String(gameId))
      .orderBy("questionId", "asc")
      .get();
  }

  /**
   * @description returns all badges
   */
  fetchBadgesList() {
    return this.fireStore
      .collection("badges")
      .ref.orderBy("badgeId", "asc")
      .get();
  }

  /**
   *
   * @param refId user document refId
   * @param gameId  user enetered gameId
   * @description returns badges earned by user for a game
   */
  getBadgesByUserId(refId, gameId) {
    return this.fireStore
      .collection("userBadges")
      .ref.where("userId", "==", refId)
      .where("gameId", "==", String(gameId))
      .orderBy("createdAt", "asc")
      .get();
  }

  /**
   *
   * @param futureDate end date of game
   * @param preDate current date
   * @description returns total no days remaining
   */
  daysDifferenceCount(futureDate, preDate) {
    let msDiff = futureDate - preDate;
    let daysDifference = msDiff / (1000 * 60 * 60 * 24);
    let daysDifferences = Math.ceil(daysDifference); //daysDifference - (daysDifference % 1)
    if (daysDifferences == 0) daysDifferences = 1;
    //return (daysDifference - (daysDifference % 1));
    return daysDifferences;
  }

  /**
   *
   * @param input server time stamp
   * @description returns server time in milliseconds and seconds
   */
  getMilliseconds(input) {
    return input?.seconds * 1000 + input?.nanoseconds / 1000;
  }

  /**
   *
   * @param roleName role name
   * @description returns role details using role name
   */
  getUserRoleUrl(roleName) {
    return this.fireStore
      .collection("userRoles")
      .ref.where("role", "==", roleName)
      .get();
  }

  /**
   *
   * @param id  questionNo
   * @description returns questions details for questionNo
   */
  getUserAnswerById(id) {
    return this.fireStore
      .collection("question")
      .ref.where("questionNo", "==", id)
      .get();
  }

  /**
   *
   * @param refId user document refId
   * @param gameId  user enetered gameId
   * @description returns badges earned by user for a game
   */
  getUserBadgesData(refId, gameId) {
    let promise = new Promise((resolve, reject) => {
      try {
        this.getUserBadgesByGameId(refId, gameId)
          .then((snapshot) => {
            let userBadgesList = [];
            snapshot.forEach((doc) => {
              userBadgesList.push(doc.data());
            });
            resolve(userBadgesList);
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(error);
        console.log("Badges data fetch issue " + error);
      }
    });
    return promise;
  }

  getUserBadgesByGameId = (refId, gameId) => {
    return this.fireStore
      .collection("userBadges")
      .ref.where("userId", "==", refId)
      .where("gameId", "==", gameId)
      .orderBy("createdAt", "asc")
      .get();
  };

  /**
   *
   * @param badgeData badges data
   * @description creates new document for badge collection.
   */

  addUserBadgeData(badgeData) {
    let bdRef = this.fireStore.collection("userBadges").ref;
    return bdRef.add(badgeData);
  }

  /**
   *
   * @param gameId gameId
   * @description returns leaderboard data using game Id
   */
  getGameLeaderBoardData(gameId) {
    return this.fireStore
      .collection("leaderBoard")
      .ref.where("gameId", "==", gameId)
      .orderBy("score.pointsEarned", "desc")
      .get();
  }

  /**
   *
   * @param refId refId of leaderboard
   * @param leaderBoardData leaderboard data
   * @description create leaderboard entry
   */

  createLeaderBoardDataByrefId(leaderBoardData) {
    return this.fireStore.collection("leaderBoard").ref.add(leaderBoardData);
  }

  /**
   *
   * @param refId refId of leaderboard
   * @param leaderBoardData leaderboard data
   * @description update leaderboard entry
   */
  updateLeaderBoardDataByrefId(refId, leaderBoardData) {
    return this.fireStore
      .collection("leaderBoard")
      .ref.doc(refId)
      .update(leaderBoardData);
  }

  /**
   *
   * @param gameId gameId
   * @param userId userId
   * @description returns leaderboard data using game Id and refId
   */
  getGameLeaderBoardDataByUserId(userId, gameId) {
    return this.fireStore
      .collection("leaderBoard")
      .ref.where("refId", "==", userId)
      .where("gameId", "==", gameId)
      .orderBy("score.pointsEarned", "desc")
      .get();
  }

  /**
   *
   * @param gameId gameId
   * @param userId userId
   * @description returns leaderboard data using game Id and refId
   */
  getGameLeaderBoardDataByRefId(userId) {
    return this.fireStore
      .collection("leaderBoard")
      .ref.where("userId", "==", userId)
      .get();
  }

  deleteUserDetails(emailId: any) {
    return this.http.post(environment.Firebase_Delete_User, {
      emailId: emailId,
    });
  }

  sendMail(data: any) {
    return this.http.post(environment.Firebase_Delete_User_Mail, data);
  }

  /**
   *
   * @param email user email checks present in firebase
   * @description returns user details from firebase
   */
  getUserDetails(email) {
    return this.fireStore
      .collection("users")
      .ref.where("emailAddress", "==", email)
      .get();
  }

  /**
   *
   * @param data contains refId and GameID
   * @description sends game summary email
   */
  sendGameSummaryEmail(data) {
    return this.http.post(environment.Firebase_Game_Summary, data);
  }

  /**
   *
   * @param refId  ref ID of user
   * @param gameData game object
   * @description push game object in game property entry
   */
  addNewGameIdForUser(refId, gameData) {
    return this.fireStore
      .collection("users")
      .ref.doc(refId)
      .update({ game: gameData });
  }

  /**
   *
   * @param email email to resubscrbe the user
   * @description remove user from unsubscribe list to receive the messages
   */
  resubscribeUser(email) {
    return this.http.post(environment.Firebase_Resubscribe_User, email);
  }

  /**
   *
   * @param email email to unsubscrbe the user
   * @description unsubscribe the user to receive the messages
   */
  unsubscribeUser(email) {
    return this.http.post(environment.Firebase_Unsubscribe_User, email);
  }

  /**
   *
   * @param email email address of user
   * @description check if email is already subscribed or not.
   */
  checkIfUserIsUnsubscribed(email) {
    return this.http.post(
      environment.Firebase_checkIfUserIsUnsubscribed,
      email
    );
  }
  getUsersByGameId(game) {
    return this.fireStore
      .collection("users")
      .ref.where("game", "array-contains", {
        gameId: game.gameId,
        gameKey: game.gameKey,
        gameTitle: game.gameTitle,
      })
      .get();
  }
}
