import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  // {
  //   path: 'auth',
  //   loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  // },
  {
    path: "gameId",
    loadChildren: () =>
      import("./modules/game/game.module").then((m) => m.GameModule),
  },
  {
    path: "unsubscribe-email/:email",
    loadChildren: () =>
      import("./unsubscribe-email/unsubscribe-email.module").then(
        (m) => m.UnsubscribeEmailModule
      ),
  },
  {
    path: "",
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "**",
    redirectTo: "dashboard",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
