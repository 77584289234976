import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../src/environments/environment";
import mixpanel from "mixpanel-browser";
import { FirebaseService } from "../../src/app/core/services/firebase.service";
import { Meta } from '@angular/platform-browser';  

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "BeaconLearning Web";
  public isDeepLink = false;
  public deepLink_gameId: any;
  public deepLink_emailAddress: any;
  userAns: any[];
  gameDetails: any;
  isDataFetched = false;
  facebookVerificationCode = "3abpqb7srq6v5ysfvhassclyy00ihb";

  constructor(
    private router: Router,
    public firebaseService: FirebaseService ,
    private meta: Meta
  ) {}
 
    appendScript(src, callbackFunc) {
      var script = document.createElement('script'); 
      script.type = 'text/javascript';
      script.src = src;
      script.onload = callbackFunc;  
      document.body.appendChild(script);
    }

  ngOnInit(): void {
    mixpanel.init(environment.mixPanelKey, { debug: true });
    if (window.location.pathname.includes("unsubscribe-email")) {
      this.isDataFetched = true;
      this.router.navigate([window.location.pathname]);
    }     
    //GTM facebook code loaded only for prod environment
    if (environment.production) {
      this.appendScript('assets/js/gtm-facebookcode.js', function () {
        var script = document.createElement('script'); 
        script.type = 'text/javascript';
        script.src = 'assets/js/gtm-facebookcode.js';  
        document.body.appendChild(script);
      });
    }
    //added facebook meta tag code only for prod environment
    if(environment.production){
      this.meta.addTag({ name: 'facebook-domain-verification', content: this.facebookVerificationCode }); 
    }
  }
}