export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyBrPYZcvZTkT0Z-mMLOkK3LYjOheY89EAg",
    authDomain: "beacon-learning-dev.firebaseapp.com",
    databaseURL: "https://beacon-learning-dev.firebaseio.com",
    projectId: "beacon-learning-dev",
    storageBucket: "beacon-learning-dev.appspot.com",
    messagingSenderId: "592890750283",
    appId: "1:592890750283:web:e38acab4621b2d1c5174ce",
    measurementId: "G-3Z6MRSSP3S"
  },
  mixPanelKey : '4b493836ecfec9600667e55cf6dbd9bf',
  baseUrl:"",
  providerBaseUrl:"",
  S3_Region: "us-east-2",
  S3_Identity_Pool_Id: "us-east-2:ede9fef0-2c12-49b0-afa4-3e8ae24253f8",
  S3_API_Version: "2006-03-01",
  S3_Bucket_Name: "beaconlearningbucket/questions_media",
  Sample_Invitee_Template: "https://beaconlearningbucket.s3.us-east-2.amazonaws.com/sample_template.csv",
  Branch_IO_Key: "key_test_boJLOrirb8KSpdqxI9t0znmcwxniKwA7",
  Cloud_Front_URL: "d320w94kh4758b.cloudfront.net",
  S3_Bucket_URL: "beaconlearningbucket.s3.us-east-2.amazonaws.com",
   App_Redirection: "https://stage-beaconlearning.app.link/DockHA33r6",
  Firebase_Delete_User: "https://us-central1-beacon-learning-dev.cloudfunctions.net/removeUserDetails",
  Firebase_Delete_User_Mail : "https://us-central1-beacon-learning-dev.cloudfunctions.net/sendRemovedUserMail",
  Firebase_Game_Summary : 'https://us-central1-beacon-learning-dev.cloudfunctions.net/sendGameSummaryMail',
  Firebase_Unsubscribe_User : 'https://us-central1-beacon-learning-dev.cloudfunctions.net/unsubscribeUser',
  Firebase_Resubscribe_User : 'https://us-central1-beacon-learning-dev.cloudfunctions.net/resubscribeUser',
  Firebase_checkIfUserIsUnsubscribed : 'https://us-central1-beacon-learning-dev.cloudfunctions.net/checkIfUserIsUnsubscribed'
};
